<template>
  <div class="not-found">
    <div class="not-found__message">
      <svg-icon name="not-found" class="not-found__image"></svg-icon>
      <Heading level="3">{{ $t('not_found.mistake') }}</Heading>
      <div class="not-found__text">{{ $t('not_found.nothing_found') }}</div>
    </div>
    <ButtonBase
      size="m"
      color="blue"
      class="not-found__to-main-btn"
      @click.native="goToDashboard"
      >{{ $t('not_found.to_dashboard') }}</ButtonBase
    >
  </div>
</template>

<script>
import Heading from '@/elements/Heading/Heading.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';

export default {
  components: { Heading, ButtonBase },
  methods: {
    goToDashboard() {
      this.$router.push({ name: 'MainPage' });
    },
  },
};
</script>

<style lang="sass" scoped>
.not-found
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: 70px

.not-found__message
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  min-width: 600px
  min-height: 420px
  margin: 32px 0 40px
  background-color: var(--primary-color-white)
  box-shadow: 0px 32px 80px rgba(38, 55, 76, 0.05)

  @media screen and (min-width: 1199px)
    min-width: 650px
    min-height: 530px
    margin: 100px 0 60px

  @media screen and (max-width: 650px)
    min-width: 100%
    min-height: 330px
    margin: 26px 0 34px

  .title
    @media screen and (max-width: 650px)
      font-size: 25px
      line-height: 30px
      margin: 30px 0 10px

.not-found__image
  width: 260px
  height: 150px

  @media screen and (max-width: 650px)
    width: 130px
    height: 75px

.not-found__text
  font-weight: normal
  font-size: 28px
  line-height: 32px

  @media screen and (max-width: 650px)
    font-size: 18px
    line-height: 22px

.not-found__to-main-btn
  width: 420px

  @media screen and (max-width: 991px)
    height: 56px

  @media screen and (max-width: 650px)
    height: 44px
    font-size: 15px

  @media screen and (max-width: 500px)
    width: 100%
</style>
